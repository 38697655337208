<template>
  <div class="container-fluid">
    <Header />
    <div
      class="mx-auto mt-1 content-outer-container"
      :class="{ 'content-dashboard': $store.state.isDashboard }"
    >
      <div class="mx-auto content-inner-container">
        <div class="wrap-pdpaUpload">
          <div class="row paj-custom-row">
            <div
              class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 custom-wrapper-dashboard"
            >
              <div class="d-flex align-items-center flex-wrap mr-2 text-title">
                <img
                  src="@/assets/images/privacy/Key.svg"
                  alt=""
                  class="mr-2"
                />
                {{ lbl['permission-type-privacy'] }}
              </div>
            </div>
            <div
              class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 custom-wrapper-dashboard"
            ></div>

            <div class="col-xl-12 col-lg-12 col-md-12 wrapper-content-tab">
              <div class="d-flex wrapper-select-header">
                <div class="select-header" :class="{ active: true }">
                  {{ lbl['pdpa-upload-menu'] }}
                </div>
                <div
                  class="select-header select-hd-right"
                  :class="{ active: false }"
                  @click="goto('PdpaUploadHistory')"
                >
                  {{ lbl['pdpa-upload-log-menu'] }}
                </div>
              </div>
            </div>
          </div>

          <!-- Form Upload -->
          <div class="mt-1 mb-3 px-3">
            <div class="row">
              <div
                class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 border-right-gray"
              >
                <a-form :form="formTerm" @submit.prevent="uploadTermSubmit">
                  <div class="row text-center mb-3">
                    <div class="col-12 mx-3 d-flex justify-center">
                      <h4 class="mb-0">{{ lbl['pdpa-term-header'] }}</h4>
                      <div
                        v-if="isNotiTerm && appId"
                        class="d-flex justify-center align-center"
                      >
                        <span class="ml-2 box-badge-upload"
                          ><img
                            src="@/assets/images/item-inactive-icon.png"
                            alt=""
                            class="item-status-icon ml-0"
                          />
                          {{ lbl['upload-file'] }}</span
                        >
                      </div>
                    </div>
                  </div>

                  <!-- PDF Term TH -->
                  <div class="row mr-2 field-row">
                    <input
                      id="thTermPdfFile"
                      ref="refTermTH"
                      type="file"
                      class="d-none"
                      accept="application/pdf"
                      @change="pdfTermTHChange"
                    />
                    <div
                      class="col col-md-2 field-label col-form-label text-right"
                    >
                      {{
                        isPhilippines
                          ? lbl['pdpa-log-en-header']
                          : lbl['pdpa-log-th-header']
                      }}
                    </div>
                    <div class="col-md-10 field-column border-bottom-w-1-pix">
                      <div class="form-inline" v-if="!dispTermTHFile">
                        <label for="thTermPdfFile">
                          <span
                            class="ant-btn btn-add-file d-flex align-center font-weight-bold"
                          >
                            <img
                              src="@/assets/images/privacy/privacy_Upload.svg"
                              alt=""
                              class="mr-2"
                            />
                            {{ lbl['pdpa-btn-add-file'] }}
                          </span>
                        </label>
                        <span v-show="errtypeTermTH" class="txt-err ml-3">
                          {{ lbl['pdpa-pdf-type-only'] }}
                        </span>
                      </div>
                      <div v-if="dispTermTHFile">
                        <img
                          class="img-fluid ico-pdf"
                          :src="require('@/assets/images/icon-pdf.png')"
                          alt=""
                        />
                        <span
                          class="mr-md-2 txt-upload-file ellipsis-long-text"
                        >
                          {{ pdfTermTHFileName }}</span
                        >
                        <img
                          @click="removeTermTHFileUpload()"
                          class="img-fluid delete-image"
                          :src="require('@/assets/images/delete-item.png')"
                          alt=""
                        />
                      </div>
                      <!-- Show Message -->
                      <div class="mt-3">
                        <!-- Description Section -->
                        <span>{{ lbl['pdpa-pdf-file-desc'] }}</span
                        ><br />
                        <!-- Error Section                   -->
                        <span v-show="errTermTHOverSize" class="txt-err">{{
                          lbl['pdpa-pdf-over-size']
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <!-- Space block -->
                  <div class="mt-4 field-row text-right">
                    <!-- Show Space block -->
                  </div>

                  <!-- PDF Term EN -->
                  <div class="row mr-2 field-row">
                    <input
                      id="enTermPdfFile"
                      ref="refTermEN"
                      type="file"
                      class="d-none"
                      accept="application/pdf"
                      @change="pdfTermENChange"
                    />
                    <div
                      class="col col-md-2 field-label col-form-label text-right"
                    >
                      {{ lbl['pdpa-log-en-header'] }}
                    </div>
                    <div class="col-md-10 field-column border-bottom-w-1-pix">
                      <div v-if="!dispTermENFile" class="form-inline">
                        <label for="enTermPdfFile">
                          <span
                            class="ant-btn btn-add-file d-flex align-center font-weight-bold"
                          >
                            <img
                              src="@/assets/images/privacy/privacy_Upload.svg"
                              alt=""
                              class="mr-2"
                            />
                            {{ lbl['pdpa-btn-add-file'] }}
                          </span>
                        </label>
                        <span v-show="errtypeTermEN" class="txt-err ml-3">
                          {{ lbl['pdpa-pdf-type-only'] }}
                        </span>
                      </div>
                      <div v-if="dispTermENFile">
                        <img
                          class="img-fluid ico-pdf"
                          alt=""
                          :src="require('@/assets/images/icon-pdf.png')"
                        />
                        <span class="mr-md-2 txt-upload-file">
                          {{ pdfTermENFileName }}</span
                        >
                        <img
                          @click="removeTermENFileUpload()"
                          class="img-fluid delete-image"
                          :src="require('@/assets/images/delete-item.png')"
                          alt=""
                        />
                      </div>

                      <!-- Show Message -->
                      <div class="mt-3">
                        <!-- Description Section -->
                        <span>{{ lbl['pdpa-pdf-file-desc'] }}</span
                        ><br />

                        <!-- Error Section -->
                        <span v-show="errTermENOverSize" class="txt-err">{{
                          lbl['pdpa-pdf-over-size']
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <!-- Term footer -->
                  <div class="mt-3 field-row text-right">
                    <div class="form-inline">
                      <!-- Term & Condition download template -->
                      <div class="margin-left-2-vw">
                        <a
                          :href="termTemplate"
                          class="txt-dwnl-temp a-pdpa-green"
                          download="file.docx"
                        >
                          {{ lbl['burn-download-temp'] }}
                        </a>
                      </div>
                      <div class="col-form-label ml-auto mr-2">
                        <a-button
                          type="primary"
                          @click="confirmTermModal"
                          :disabled="disabledTermSubmit || !appId"
                          :class="{ 'disable-upload': !appId }"
                        >
                          {{ lbl['pdpa-upload-menu'] }}
                        </a-button>
                      </div>
                    </div>
                  </div>
                </a-form>
              </div>

              <div
                class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 border-right-gray"
              >
                <a-form
                  :form="formPrivacy"
                  @submit.prevent="uploadPrivacySubmit"
                >
                  <div class="row text-center mb-3">
                    <div class="col-12 mx-3 d-flex justify-center">
                      <h4 class="mb-0">{{ lbl['pdpa-privacy-header'] }}</h4>
                      <div
                        v-if="isNotiPrivacy && appId"
                        class="d-flex justify-center align-center"
                      >
                        <span class="ml-2 box-badge-upload"
                          ><img
                            src="@/assets/images/item-inactive-icon.png"
                            class="item-status-icon ml-0"
                            alt=""
                          />
                          {{ lbl['upload-file'] }}</span
                        >
                      </div>
                    </div>
                  </div>

                  <!-- PDF Privacy TH -->
                  <div class="row mr-2 field-row">
                    <input
                      id="thPrivacyPdfFile"
                      ref="refPrivacyTH"
                      type="file"
                      class="d-none"
                      accept="application/pdf"
                      @change="pdfPrivacyTHChange"
                    />
                    <div
                      class="col col-md-2 field-label col-form-label text-right"
                    >
                      {{
                        isPhilippines
                          ? lbl['pdpa-log-en-header']
                          : lbl['pdpa-log-th-header']
                      }}
                    </div>
                    <div class="col-md-10 field-column border-bottom-w-1-pix">
                      <div v-if="!dispPrivacyTHFile" class="form-inline">
                        <label for="thPrivacyPdfFile">
                          <span
                            class="ant-btn btn-add-file d-flex align-center font-weight-bold"
                          >
                            <img
                              src="@/assets/images/privacy/privacy_Upload.svg"
                              alt=""
                              class="mr-2"
                            />
                            {{ lbl['pdpa-btn-add-file'] }}
                          </span>
                        </label>
                        <span v-show="errtypePrivacyTH" class="txt-err ml-3">
                          {{ lbl['pdpa-pdf-type-only'] }}
                        </span>
                      </div>
                      <div v-if="dispPrivacyTHFile">
                        <img
                          class="img-fluid ico-pdf"
                          :src="require('@/assets/images/icon-pdf.png')"
                          alt=""
                        />
                        <span class="mr-md-2 txt-upload-file">
                          {{ pdfPrivacyTHFileName }}</span
                        >
                        <img
                          @click="removePrivacyTHFileUpload()"
                          class="img-fluid delete-image"
                          :src="require('@/assets/images/delete-item.png')"
                          alt=""
                        />
                      </div>
                      <!-- Show Message -->
                      <div class="mt-3">
                        <!-- Description Section -->
                        <span>{{ lbl['pdpa-pdf-file-desc'] }}</span
                        ><br />
                        <!-- Error Section                   -->
                        <span v-show="errPrivacyTHOverSize" class="txt-err">{{
                          lbl['pdpa-pdf-over-size']
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <!-- Space block -->
                  <div class="mt-4 field-row text-right">
                    <!-- Show Space block -->
                  </div>

                  <!-- PDF Privacy EN -->
                  <div class="row mr-2 field-row">
                    <input
                      id="enPrivacyPdfFile"
                      ref="refPrivacyEN"
                      type="file"
                      class="d-none"
                      accept="application/pdf"
                      @change="pdfPrivacyENChange"
                    />
                    <div
                      class="col col-md-2 field-label col-form-label text-right"
                    >
                      {{ lbl['pdpa-log-en-header'] }}
                    </div>
                    <div class="col-md-10 field-column border-bottom-w-1-pix">
                      <div v-if="!dispPrivacyENFile" class="form-inline">
                        <label for="enPrivacyPdfFile">
                          <span
                            class="ant-btn btn-add-file d-flex align-center font-weight-bold"
                          >
                            <img
                              src="@/assets/images/privacy/privacy_Upload.svg"
                              alt=""
                              class="mr-2"
                            />
                            {{ lbl['pdpa-btn-add-file'] }}
                          </span>
                        </label>
                        <span v-show="errtypePrivacyEN" class="txt-err ml-3">
                          {{ lbl['pdpa-pdf-type-only'] }}
                        </span>
                      </div>
                      <div v-if="dispPrivacyENFile">
                        <img
                          class="img-fluid ico-pdf"
                          :src="require('@/assets/images/icon-pdf.png')"
                          alt=""
                        />
                        <span class="mr-md-2 txt-upload-file">
                          {{ pdfPrivacyENFileName }}</span
                        >
                        <img
                          @click="removePrivacyENFileUpload()"
                          class="img-fluid delete-image"
                          :src="require('@/assets/images/delete-item.png')"
                          alt=""
                        />
                      </div>
                      <div class="mt-3">
                        <span>{{ lbl['pdpa-pdf-file-desc'] }}</span
                        ><br />
                        <span v-if="errPrivacyENOverSize" class="txt-err">{{
                          lbl['pdpa-pdf-over-size']
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <!-- Privacy footer -->
                  <div class="mt-3 field-row text-right">
                    <div class="form-inline">
                      <!-- Privacy download template -->
                      <div class="margin-left-2-vw">
                        <a
                          :href="privacyTemplate"
                          class="txt-dwnl-temp a-pdpa-green"
                          download="file.docx"
                        >
                          {{ lbl['burn-download-temp'] }}
                        </a>
                      </div>
                      <div class="col-form-label ml-auto mr-2">
                        <a-button
                          type="primary"
                          @click="confirmPrivacyModal"
                          :disabled="disabledPrivacySubmit || !appId"
                          :class="{ 'disable-upload': !appId }"
                        >
                          {{ lbl['pdpa-upload-menu'] }}
                        </a-button>
                      </div>
                    </div>
                  </div>
                </a-form>
              </div>

              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                <a-form
                  :form="formMarketing"
                  @submit.prevent="uploadMarketingSubmit"
                >
                  <div class="row text-center mb-3">
                    <div class="col-12 mx-3 d-flex justify-center">
                      <h4 class="mb-0">
                        {{ lbl['marketing-message-header'] }}
                      </h4>
                      <div
                        v-if="isNotiMarketing && appId"
                        class="d-flex justify-center align-center"
                      >
                        <span class="ml-2 box-badge-upload"
                          ><img
                            src="@/assets/images/item-inactive-icon.png"
                            class="item-status-icon ml-0"
                            alt=""
                          />
                          {{ lbl['upload-file'] }}</span
                        >
                      </div>
                    </div>
                  </div>

                  <!-- PDF Marketing TH -->
                  <div class="row mr-2 field-row">
                    <input
                      id="thMarketingPdfFile"
                      ref="refMarketingTH"
                      type="file"
                      class="d-none"
                      accept="application/pdf"
                      @change="pdfMarketingTHChange"
                    />
                    <div
                      class="col col-md-2 field-label col-form-label text-right"
                    >
                      {{
                        isPhilippines
                          ? lbl['pdpa-log-en-header']
                          : lbl['pdpa-log-th-header']
                      }}
                    </div>
                    <div class="col-md-10 field-column border-bottom-w-1-pix">
                      <div v-if="!dispMarketingTHFile" class="form-inline">
                        <label for="thMarketingPdfFile">
                          <span
                            class="ant-btn btn-add-file d-flex align-center font-weight-bold"
                          >
                            <img
                              src="@/assets/images/privacy/privacy_Upload.svg"
                              alt=""
                              class="mr-2"
                            />
                            {{ lbl['pdpa-btn-add-file'] }}
                          </span>
                        </label>
                        <span v-show="errtypeMarketingTH" class="txt-err ml-3">
                          {{ lbl['pdpa-pdf-type-only'] }}
                        </span>
                      </div>
                      <div v-if="dispMarketingTHFile">
                        <img
                          class="img-fluid ico-pdf"
                          :src="require('@/assets/images/icon-pdf.png')"
                          alt=""
                        />
                        <span class="mr-md-2 txt-upload-file">
                          {{ pdfMarketingTHFileName }}</span
                        >
                        <img
                          @click="removeMarketingTHFileUpload()"
                          class="img-fluid delete-image"
                          :src="require('@/assets/images/delete-item.png')"
                          alt=""
                        />
                      </div>
                      <!-- Show Message -->
                      <div class="mt-3">
                        <!-- Description Section -->
                        <span>{{ lbl['pdpa-pdf-file-desc'] }}</span
                        ><br />
                        <!-- Error Section                   -->
                        <span v-show="errMarketingTHOverSize" class="txt-err">{{
                          lbl['pdpa-pdf-over-size']
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <!-- Space block -->
                  <div class="mt-4 field-row text-right">
                    <!-- Show Space block -->
                  </div>

                  <!-- PDF Marketing EN -->
                  <div class="row mr-2 field-row">
                    <input
                      id="enMarketingPdfFile"
                      ref="refMarketingEN"
                      type="file"
                      class="d-none"
                      accept="application/pdf"
                      @change="pdfMarketingENChange"
                    />
                    <div
                      class="col col-md-2 field-label col-form-label text-right"
                    >
                      {{ lbl['pdpa-log-en-header'] }}
                    </div>
                    <div class="col-md-10 field-column border-bottom-w-1-pix">
                      <div v-if="!dispMarketingENFile" class="form-inline">
                        <label for="enMarketingPdfFile">
                          <span
                            class="ant-btn btn-add-file d-flex align-center font-weight-bold"
                          >
                            <img
                              src="@/assets/images/privacy/privacy_Upload.svg"
                              alt=""
                              class="mr-2"
                            />
                            {{ lbl['pdpa-btn-add-file'] }}
                          </span>
                        </label>
                        <span v-show="errtypeMarketingEN" class="txt-err ml-3">
                          {{ lbl['pdpa-pdf-type-only'] }}
                        </span>
                      </div>
                      <div v-if="dispMarketingENFile">
                        <img
                          class="img-fluid ico-pdf"
                          :src="require('@/assets/images/icon-pdf.png')"
                          alt=""
                        />
                        <span class="mr-md-2 txt-upload-file">
                          {{ pdfMarketingENFileName }}</span
                        >
                        <img
                          @click="removeMarketingENFileUpload()"
                          class="img-fluid delete-image"
                          :src="require('@/assets/images/delete-item.png')"
                          alt=""
                        />
                      </div>
                      <div class="mt-3">
                        <span>{{ lbl['pdpa-pdf-file-desc'] }}</span
                        ><br />
                        <span v-if="errMarketingENOverSize" class="txt-err">{{
                          lbl['pdpa-pdf-over-size']
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <!-- Marketing footer -->
                  <div class="mt-3 field-row text-right">
                    <div class="form-inline">
                      <!-- Marketing download template -->
                      <div class="margin-left-2-vw">
                        <a
                          :href="marketingTemplate"
                          class="txt-dwnl-temp a-pdpa-green"
                          download="file.docx"
                        >
                          {{ lbl['burn-download-temp'] }}
                        </a>
                      </div>
                      <div class="col-form-label ml-auto mr-2">
                        <a-button
                          type="primary"
                          @click="confirmMarketingModal"
                          :disabled="disabledMarketingSubmit || !appId"
                          :class="{ 'disable-upload': !appId }"
                        >
                          {{ lbl['pdpa-upload-menu'] }}
                        </a-button>
                      </div>
                    </div>
                  </div>
                </a-form>
              </div>
            </div>
          </div>
          <b-alert
            variant="success"
            dismissible
            fade
            :show="uploadCompleted"
            @dismissed="uploadCompleted = false"
          >
            {{ lbl['pdpa-upload-complete'] }}
          </b-alert>
        </div>
      </div>
    </div>
    <ConfirmModal
      :isOpenModal="confirmModalSetting.isOpenModal"
      :modalType="confirmModalSetting.modalType"
      :title="confirmModalSetting.title"
      :details="confirmModalSetting.details"
      :backto="confirmModalSetting.backto"
      :onConfirm="confirmModalSetting.onConfirm"
      :onCancel="confirmModalSetting.onCancel"
    />
    <AlertModal
      :isOpenModal="alertModalSetting.isOpenModal"
      :modalType="alertModalSetting.modalType"
      :title="alertModalSetting.title"
      :details="alertModalSetting.details"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import Mixin from '@/mixin/Mixin'
import Account from '@/helper/AccountHelper.js'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import BzbsPdpa from '@/core/Settings/BzbsSettingsPdpa'
import Header from '@/components/Layout/Header'
import ConfirmModal from '@/components/Modal/Component/confirmmodal2'
import AlertModal from '@/components/Modal/Component/alertmodal2'

export default {
  name: 'PdpaUpload',
  mixins: [Mixin],
  components: {
    Header,
    ConfirmModal,
    AlertModal,
  },
  data: function () {
    return {
      isPhilippines: false,
      host: window.location.origin,
      disabledPrivacySubmit: true,
      disabledTermSubmit: true,
      disabledMarketingSubmit: true,
      dispPrivacyENFile: false,
      dispPrivacyTHFile: false,
      dispTermENFile: false,
      dispTermTHFile: false,
      dispMarketingENFile: false,
      dispMarketingTHFile: false,
      errPrivacyENOverSize: false,
      errPrivacyTHOverSize: false,
      errTermENOverSize: false,
      errTermTHOverSize: false,
      errMarketingENOverSize: false,
      errMarketingTHOverSize: false,
      errtypePrivacyEN: false,
      errtypePrivacyTH: false,
      errtypeTermEN: false,
      errtypeTermTH: false,
      errtypeMarketingEN: false,
      errtypeMarketingTH: false,
      fileReader: [],
      fileTermEN: null,
      fileTermTH: null,
      filePrivacyEN: null,
      filePrivacyTH: null,
      fileMarketingEN: null,
      fileMarketingTH: null,
      isConsent: false,
      pdfPrivacyENFileName: '',
      pdfPrivacyTHFileName: '',
      pdfTermENFileName: '',
      pdfTermTHFileName: '',
      pdfMarketingENFileName: '',
      pdfMarketingTHFileName: '',
      uploadCompleted: false,
      termTemplate: '',
      thTermTemplate: '',
      enTermTemplate: '',
      privacyTemplate: '',
      thPrivacyTemplate: '',
      enPrivacyTemplate: '',
      marketingTemplate: '',
      thMarketingTemplate: '',
      enMarketingTemplate: '',
      confirmModalSetting: {
        title: '',
        detail: '',
        modalType: '',
        backto: '',
        onConfirm: null,
        onCancel: null,
        isOpenModal: false,
      },
      alertModalSetting: {
        title: '',
        detail: '',
        modalType: '',
        isOpenModal: false,
      },
      isNotiTerm: false,
      isNotiPrivacy: false,
      isNotiMarketing: false,
      appId: null,
    }
  },
  beforeCreate() {
    this.formTerm = this.$form.createForm(this, { name: 'pdpauploadterm' })
    this.formPrivacy = this.$form.createForm(this, {
      name: 'pdpauploadprivacy',
    })
    this.formMarketing = this.$form.createForm(this, {
      name: 'uploadmarketing',
    })
  },
  created() {
    this.handleFooter(true)
    Account.bzbsAnalyticTracking(
      'privacy_conditions_page',
      'privacy_conditions',
      'view_privacy_conditions',
      'on view',
    )
    if (Vue.bzbsConfig.businessRule.webFor.PH) {
      this.isPhilippines = true
    }
    this.init()
  },
  watch: {
    fileTermEN: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.activeTermUpload()
      }
    },
    fileTermTH: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.activeTermUpload()
      }
    },
    filePrivacyEN: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.activePrivacyUpload()
      }
    },
    filePrivacyTH: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.activePrivacyUpload()
      }
    },
    fileMarketingEN: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.activeMarketingUpload()
      }
    },
    fileMarketingTH: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.activeMarketingUpload()
      }
    },
  },
  methods: {
    init() {
      this.thTermTemplate =
        Vue.bzbsConfig.bzbsBlobUrl + '/config/crmplus/privacy/termTH_TH.docx'
      this.enTermTemplate =
        Vue.bzbsConfig.bzbsBlobUrl + '/config/crmplus/privacy/termEN_US.docx'
      this.thPrivacyTemplate =
        Vue.bzbsConfig.bzbsBlobUrl + '/config/crmplus/privacy/privacyTH_TH.docx'
      this.enPrivacyTemplate =
        Vue.bzbsConfig.bzbsBlobUrl + '/config/crmplus/privacy/privacyEN_US.docx'
      this.thMarketingTemplate =
        Vue.bzbsConfig.bzbsBlobUrl +
        '/config/crmplus/marketing/marketingTH_TH.docx'
      this.enMarketingTemplate =
        Vue.bzbsConfig.bzbsBlobUrl +
        '/config/crmplus/marketing/marketingEN_US.docx'
      this.checkPdpaUploadLog()
      this.getCRMPlusProfile().then(result => {
        if (typeof result.IsAlreadyPoint == 'boolean') {
          this.isAlreadyPoint = result.IsAlreadyPoint
        }
        if (this.isAlreadyPoint) {
          this.campaignmode = this.lbl['import-mode-point']
        } else {
          this.campaignmode = this.lbl['import-mode-stamp']
        }

        // Modify by Achira on 202110061742 edit by u-199288
        this.isConsent = result.Ispdpa

        if (this.localecode == 'th') {
          this.termTemplate = this.thTermTemplate
          this.privacyTemplate = this.thPrivacyTemplate
          this.marketingTemplate = this.thMarketingTemplate
        } else {
          this.termTemplate = this.enTermTemplate
          this.privacyTemplate = this.enPrivacyTemplate
          this.marketingTemplate = this.enMarketingTemplate
        }
      })
    },
    confirmModalAction(
      title,
      details,
      modalType,
      backto,
      isOpenModal,
      onConfirm,
      onCancel,
    ) {
      this.confirmModalSetting.title = title
      this.confirmModalSetting.details = details
      this.confirmModalSetting.modalType = modalType
      this.confirmModalSetting.isOpenModal = isOpenModal
      this.confirmModalSetting.backto = backto
      this.confirmModalSetting.onConfirm = onConfirm
      this.confirmModalSetting.onCancel = onCancel
    },
    alertModalAction(title, details, modalType, isOpenModal) {
      this.alertModalSetting.title = title
      this.alertModalSetting.details = details
      this.alertModalSetting.modalType = modalType
      this.alertModalSetting.isOpenModal = isOpenModal
    },
    getCRMPlusProfile() {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            this.handleLoading(false)
            this.appId = res.data.AppId
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            resolve(error)
          })
      })
    },
    changePdpaConsent() {
      this.handleLoading(true)
      BzbsPdpa.postPdpaConsent(this.isConsent)
        .then(res => {
          this.handleLoading(false)
          console.log(res)
        })
        .catch(err => {
          this.handleLoading(false)
          console.log(err)
        })
    },
    uploadTermSubmit() {
      this.handleLoading(true)

      BzbsPdpa.postUploadTermandCondition(this.fileTermTH, this.fileTermEN)
        .then(res => {
          this.handleLoading(false)
          this.removeTermENFileUpload()
          this.removeTermTHFileUpload()
          this.alertModalAction(
            this.lbl['alert-box-success-header'],
            this.lbl['pdpa-upload-complete'],
            'success',
            true,
          )
          setTimeout(() => {
            this.alertModalSetting.isOpenModal = false
            this.goto('PdpaUploadHistory')
            console.log(res)
          }, 2000)
        })
        .catch(err => {
          this.handleLoading(false)
          console.log(err)
        })
    },
    pdfTermTHChange(event) {
      this.handleLoading(true)
      this.clearTermTHError()

      var file = event.target.files[0]
      var valid = this.validatePdfType(file)
      var sizevalid = this.validatePdfSize(file)

      if (!sizevalid) {
        this.errTermTHOverSize = true
        this.handleLoading(false)
      }

      if (valid) {
        this.fileTermTH = file

        this.dispTermTHFile = true
        this.pdfTermTHFileName = file.name
        this.handleLoading(false)
      } else {
        this.errtypeTermTH = !valid
        this.handleLoading(false)
      }
      this.$forceUpdate()
    },
    removeTermTHFileUpload() {
      this.pdfTermTHFileName = ''
      this.fileTermTH = null
      this.clearTermTHError()
    },
    clearTermTHError() {
      this.dispTermTHFile = false
      this.errtypeTermTH = false
      this.errTermTHOverSize = false
    },
    pdfTermENChange(event) {
      this.handleLoading(true)

      this.clearTermENError()

      var file = event.target.files[0]
      var valid = this.validatePdfType(file)
      var sizevalid = this.validatePdfSize(file)

      if (!sizevalid) {
        this.errTermENOverSize = true
        this.handleLoading(false)
      }

      if (valid) {
        this.fileTermEN = file

        this.dispTermENFile = true
        this.pdfTermENFileName = file.name
        this.handleLoading(false)
      } else {
        this.errtypeTermEN = !valid
        this.handleLoading(false)
      }
    },
    removeTermENFileUpload() {
      this.pdfTermENFileName = ''
      this.fileTermEN = null
      this.clearTermENError()
    },
    clearTermENError() {
      this.dispTermENFile = false
      this.errtypeTermEN = false
      this.errTermENOverSize = false
    },
    uploadPrivacySubmit() {
      this.handleLoading(true)

      BzbsPdpa.postUploadPrivacy(this.filePrivacyTH, this.filePrivacyEN)
        .then(res => {
          this.handleLoading(false)
          this.removePrivacyENFileUpload()
          this.removePrivacyTHFileUpload()
          this.alertModalAction(
            this.lbl['alert-box-success-header'],
            this.lbl['pdpa-upload-complete'],
            'success',
            true,
          )
          setTimeout(() => {
            this.alertModalSetting.isOpenModal = false
            this.$refs.refPrivacyTH.values = ''
            this.$forceUpdate()
            this.goto('PdpaUploadHistory')
            console.log(res)
          }, 2000)
        })
        .catch(err => {
          this.handleLoading(false)
          console.log(err)
        })
    },
    pdfPrivacyTHChange(event) {
      this.handleLoading(true)

      this.clearPrivacyTHError()

      var file = event.target.files[0]
      var valid = this.validatePdfType(file)
      var sizevalid = this.validatePdfSize(file)

      if (!sizevalid) {
        this.errPrivacyTHOverSize = true
        this.handleLoading(false)
      }

      if (valid) {
        this.filePrivacyTH = file

        this.dispPrivacyTHFile = true
        this.pdfPrivacyTHFileName = file.name
        this.handleLoading(false)
      } else {
        this.errtypePrivacyTH = !valid
        this.handleLoading(false)
      }
      this.$forceUpdate()
    },
    removePrivacyTHFileUpload() {
      this.pdfPrivacyTHFileName = ''
      this.filePrivacyTH = null
      this.clearPrivacyTHError()
    },
    clearPrivacyTHError() {
      this.dispPrivacyTHFile = false
      this.errtypePrivacyTH = false
      this.errPrivacyTHOverSize = false
    },
    pdfPrivacyENChange(event) {
      this.handleLoading(true)

      this.clearPrivacyENError()

      var file = event.target.files[0]
      var valid = this.validatePdfType(event.target.files[0])
      var sizevalid = this.validatePdfSize(event.target.files[0])

      if (!sizevalid) {
        this.errPrivacyENOverSize = true
        this.handleLoading(false)
      }

      if (valid) {
        this.filePrivacyEN = file

        this.dispPrivacyENFile = true
        this.pdfPrivacyENFileName = file.name
        this.handleLoading(false)
      } else {
        this.errtypePrivacyEN = !valid
        this.handleLoading(false)
      }
      this.$forceUpdate()
    },
    removePrivacyENFileUpload() {
      this.pdfPrivacyENFileName = ''
      this.filePrivacyEN = null
      this.clearPrivacyENError()
    },
    clearPrivacyENError() {
      this.dispPrivacyENFile = false
      this.errtypePrivacyEN = false
      this.errPrivacyENOverSize = false
    },
    validatePdfType(file) {
      if (!file) return
      var isType = true
      const isPdf = file.type == 'application/pdf'

      if (!isPdf) {
        this.errtype = true
        isType = false
      }
      return isType
    },
    validatePdfSize(file) {
      if (!file) return
      var isSize = true
      if (file.size > 1024 * 1024) {
        isSize = false
      }
      return isSize
    },
    activeTermUpload() {
      if (this.fileTermEN && this.fileTermTH) {
        this.disabledTermSubmit = false
      } else {
        this.disabledTermSubmit = true
      }
      this.$forceUpdate()
    },
    activePrivacyUpload() {
      if (this.filePrivacyEN && this.filePrivacyTH) {
        this.disabledPrivacySubmit = false
      } else {
        this.disabledPrivacySubmit = true
      }
      this.$forceUpdate()
    },
    confirmTermModal() {
      Account.bzbsAnalyticTracking(
        'privacy_conditions_page',
        'privacy_conditions',
        'click_term_upload',
        'on click',
      )
      this.confirmModalAction(
        this.lbl['plan-reward-point-burn-manage-save-confirm-title'],
        this.lbl['adjust-confirm-text'],
        'warning',
        this.lbl['confirm-box-cancel-button'],
        true,
        () => {
          this.confirmModalSetting.isOpenModal = false
          this.uploadTermSubmit()
        },
        () => {
          this.confirmModalSetting.isOpenModal = false
        },
      )
    },
    confirmPrivacyModal() {
      Account.bzbsAnalyticTracking(
        'privacy_conditions_page',
        'privacy_conditions',
        'click_privacy_upload',
        'on click',
      )
      this.confirmModalAction(
        this.lbl['plan-reward-point-burn-manage-save-confirm-title'],
        this.lbl['adjust-confirm-text'],
        'warning',
        this.lbl['confirm-box-cancel-button'],
        true,
        () => {
          this.confirmModalSetting.isOpenModal = false
          this.uploadPrivacySubmit()
        },
        () => {
          this.confirmModalSetting.isOpenModal = false
        },
      )
    },

    // Marketing Message
    pdfMarketingTHChange(event) {
      this.handleLoading(true)

      this.clearMarketingTHError()

      var file = event.target.files[0]
      var valid = this.validatePdfType(file)
      var sizevalid = this.validatePdfSize(file)

      if (!sizevalid) {
        this.errMarketingTHOverSize = true
        this.handleLoading(false)
      }

      if (valid) {
        this.fileMarketingTH = file

        this.dispMarketingTHFile = true
        this.pdfMarketingTHFileName = file.name
        this.handleLoading(false)
      } else {
        this.errtypeMarketingTH = !valid
        this.handleLoading(false)
      }
      this.$forceUpdate()
    },
    clearMarketingTHError() {
      this.dispMarketingTHFile = false
      this.errtypeMarketingTH = false
      this.errMarketingTHOverSize = false
    },
    activeMarketingUpload() {
      if (this.fileMarketingEN && this.fileMarketingTH) {
        this.disabledMarketingSubmit = false
      } else {
        this.disabledMarketingSubmit = true
      }
      this.$forceUpdate()
    },
    removeMarketingTHFileUpload() {
      this.pdfMarketingTHFileName = ''
      this.fileMarketingTH = null
      this.clearMarketingTHError()
    },
    pdfMarketingENChange(event) {
      this.handleLoading(true)

      this.clearMarketingENError()

      var file = event.target.files[0]
      var valid = this.validatePdfType(event.target.files[0])
      var sizevalid = this.validatePdfSize(event.target.files[0])

      if (!sizevalid) {
        this.errMarketingENOverSize = true
        this.handleLoading(false)
      }

      if (valid) {
        this.fileMarketingEN = file

        this.dispMarketingENFile = true
        this.pdfMarketingENFileName = file.name
        this.handleLoading(false)
      } else {
        this.errtypeMarketingEN = !valid
        this.handleLoading(false)
      }
      this.$forceUpdate()
    },
    clearMarketingENError() {
      this.dispMarketingENFile = false
      this.errtypeMarketingEN = false
      this.errMarketingENOverSize = false
    },
    removeMarketingENFileUpload() {
      this.pdfMarketingENFileName = ''
      this.fileMarketingEN = null
      this.clearMarketingENError()
    },
    confirmMarketingModal() {
      Account.bzbsAnalyticTracking(
        'privacy_conditions_page',
        'privacy_conditions',
        'click_marketing_message_upload',
        'on click',
      )
      this.confirmModalAction(
        this.lbl['plan-reward-point-burn-manage-save-confirm-title'],
        this.lbl['adjust-confirm-text'],
        'warning',
        this.lbl['confirm-box-cancel-button'],
        true,
        () => {
          this.confirmModalSetting.isOpenModal = false
          this.uploadMarketingSubmit()
        },
        () => {
          this.confirmModalSetting.isOpenModal = false
        },
      )
    },
    uploadMarketingSubmit() {
      this.handleLoading(true)

      BzbsPdpa.postUploadMarketing(this.fileMarketingTH, this.fileMarketingEN)
        .then(res => {
          this.handleLoading(false)
          this.removeMarketingENFileUpload()
          this.removeMarketingTHFileUpload()
          this.alertModalAction(
            this.lbl['alert-box-success-header'],
            this.lbl['pdpa-upload-complete'],
            'success',
            true,
          )
          setTimeout(() => {
            this.alertModalSetting.isOpenModal = false
            this.$refs.refMarketingTH.values = ''
            this.$refs.refMarketingEN.values = ''
            this.$forceUpdate()
            this.goto('PdpaUploadHistory')
            console.log(res)
          }, 2000)
        })
        .catch(err => {
          this.handleLoading(false)
          console.log(err)
        })
    },
    goto(page) {
      Account.bzbsAnalyticTracking(
        'import_member_page',
        'import_member',
        'click_condition_history',
        'on click',
      )

      this.$router.push({ name: page })
    },
    checkPdpaUploadLog() {
      this.isNotiTerm = false
      this.isNotiPrivacy = false
      this.isNotiMarketing = false

      this.getTotalPdpaUploadLog('terms').then(terms => {
        if (terms == 0) {
          this.isNotiTerm = true
        }
      })

      this.getTotalPdpaUploadLog('privacy').then(privacy => {
        if (privacy == 0) {
          this.isNotiPrivacy = true
        }
      })

      this.getTotalPdpaUploadLog('marketing').then(marketing => {
        if (marketing == 0) {
          this.isNotiMarketing = true
        }
      })
    },
    getTotalPdpaUploadLog(type) {
      return new Promise(resolve => {
        BzbsPdpa.getImportConsentLog(type, 10, 0)
          .then(res => {
            resolve(res.data.total)
          })
          .catch(error => {
            resolve(error)
          })
      })
    },
  },
}
</script>

<style lang="scss">
@import 'style/PdpaUpload.scss';

.wrap-pdpaUpload {
  .box-badge-upload {
    border: 1px solid red;
    padding: 2px 10px;
    border-radius: 15px;
    color: red;
    font-size: 12px;
    font-weight: 500;
  }
}
</style>
